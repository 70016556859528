import { css } from '@emotion/react';
import { Button, Typography } from '@mui/material';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

// Local
import ConsentCheckbox from './ConsentCheckbox';
import SecurityLogos from './SecurityLogos';
import { payPalColor } from '@/assets';
import SubscriptionTermsCheckbox from '@/components/cart/SubscriptionTermsCheckbox';
import { getZoneStyles } from '@/components/mainContent/contentStyle';
import { MediaQueryContext } from '@/state/MediaQueryContext';
import { useStore } from '@/state/stores';
import {
	cbPrimary,
	checkoutTheme,
	defaultButtonOutlineColor,
	defaultButtonTextColor,
	defaultRedButtonBackgroundColor,
} from '@/theme';
import { CountdownTimerActionType, PaymentMethods } from '@/utils/enums';
import { cartIncludesRecurring } from '@/utils/helpers';

const style = (cartStyles) => {
	const { button } = cartStyles || {};

	return css`
		padding-top: 1rem;

		@supports (-webkit-appearance: -apple-pay-button) {
			.apple-pay-button.apple-pay-button-black {
				height: 3.125rem;
				width: 100%;
				display: inline-block;
				-webkit-appearance: -apple-pay-button;
				-apple-pay-button-type: buy;
				-apple-pay-button-style: black;
				box-shadow:
					rgb(0 0 0 / 20%) 0px 3px 1px -2px,
					rgb(0 0 0 / 14%) 0px 2px 2px 0px,
					rgb(0 0 0 / 12%) 0px 1px 5px 0px;

				&:hover {
					cursor: pointer;
				}

				&:disabled {
					cursor: not-allowed;
				}
			}
		}

		@supports not (-webkit-appearance: -apple-pay-button) {
			.apple-pay-button.apple-pay-button-black {
				display: inline-block;
				background-size: 100% 50%;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				border-radius: 5px;
				padding: 0px;
				box-sizing: border-box;
				min-width: 200px;
				height: 3.125rem;
				background-image: -webkit-named-image(apple-pay-logo-white);
				background-color: black;
				border: 1px solid black;
				box-shadow:
					rgb(0 0 0 / 20%) 0px 3px 1px -2px,
					rgb(0 0 0 / 14%) 0px 2px 2px 0px,
					rgb(0 0 0 / 12%) 0px 1px 5px 0px;
				width: 100%;

				&:hover {
					cursor: pointer;
				}

				&:disabled {
					cursor: not-allowed;
				}
			}
		}

		button {
			position: relative;
			display: flex;
			margin-left: auto;
			margin-right: auto;
			min-height: 3.125rem;
			font-size: 1.5rem;
			font-weight: bold;

			${checkoutTheme.breakpoints.up('md')} {
				max-width: initial;
			}

			border-radius: ${button?.borderRadius || '0px'};
			background-color: ${button?.backgroundColor || defaultRedButtonBackgroundColor};
			border: ${button?.borderSize || '1px'} solid
				${button?.borderColor || defaultButtonOutlineColor};
			color: ${button?.fontColor || defaultButtonTextColor};
			font-family: ${button?.fontFamily || 'inherit'};

			&:hover {
				background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
				background-color: ${button?.backgroundColor || defaultRedButtonBackgroundColor};
			}

			&:disabled {
				//  grayscale .40 here so it handles the darker custom borders better
				filter: grayscale(0.4);
			}

			&.paypal-button {
				background: #ffcf66;
				color: #003087;

				// keeps custom styles from affecting the paypal button
				border: none;

				&:disabled {
					filter: grayscale(1);
				}
			}

			.button-text {
				display: flex;
				align-items: center;
			}
		}

		.paypal {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;
			margin: -0.1875rem 0 -0.1875rem -0.1875rem;

			* {
				margin: 0.1875rem 0.375rem;
			}
		}

		.paypal-text {
			white-space: nowrap;
		}

		.paypal-logo {
			width: 7.125rem;
			height: auto;
		}

		.paze-button {
			background: ${cbPrimary[500]};
			background-color: ${button?.backgroundColor || cbPrimary[500]};

			&:hover {
				background-color: ${button?.backgroundColor || cbPrimary[500]};
			}
		}

		.spinner-overlay {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.spinner {
			color: ${checkoutTheme.palette.action.disabled};
		}

		.security-logos-container {
			padding-top: 1.5rem;

			${checkoutTheme.breakpoints.down('sm')} {
				padding-top: 1rem;
			}
		}
	`;
};

const SubmitButton = () => {
	const { t } = useTranslation(['checkout']);
	const {
		paymentMethod,
		orderTotals,
		isCartUpdating,
		submitOverlayVisible,
		template,
		selectedLanguage,
		cartStatus,
		regulations,
		setRegulations,
		pazeData,
		countdownTimerHasExpired,
	} = useStore(
		useShallow((state) => ({
			pazeData: state.pazeData,
			paymentMethod: state.paymentMethod,
			orderTotals: state.orderTotals,
			isCartUpdating: state.isCartUpdating,
			submitOverlayVisible: state.submitOverlayVisible,
			template: state.template,
			selectedLanguage: state.selectedLanguage,
			cartStatus: state.cartStatus,
			regulations: state.regulations,
			setRegulations: state.setRegulations,
			countdownTimerHasExpired: state.countdownTimerHasExpired,
		})),
	);

	const { isSmallScreen } = useContext(MediaQueryContext);
	const cartStyles = getZoneStyles(template, 'cart');
	const i18nKey = cartStyles?.button?.text || 'pay-now';

	const buttonTextMap = {
		[PaymentMethods.CREDIT]: t(`form.button.${i18nKey}`),
		[PaymentMethods.PAZE]: t(`form.button.${i18nKey}`),
		[PaymentMethods.PAYPAL]: (
			<div className="paypal">
				<span className="paypal-text">{t('form.button.continue')}</span>
				<img
					src={payPalColor}
					alt={t('alt-tags.paypal-logo')}
					className="paypal-logo"
					width="114"
					height="28"
				/>
			</div>
		),
	};

	const legalTextMap = {
		[PaymentMethods.CREDIT]: t(`form.button.${i18nKey}`),
		[PaymentMethods.PAZE]: t(`form.button.${i18nKey}`),
		[PaymentMethods.PAYPAL]: t(`form.button.continue-to-paypal`),
	};

	const buttonText = <span className="button-text">{buttonTextMap[paymentMethod]}</span>;
	const notAvailableActionTriggered =
		countdownTimerHasExpired &&
		template.countdownTimer.actionType === CountdownTimerActionType.NOT_AVAILABLE;

	const disableSubmit =
		isCartUpdating ||
		orderTotals === null ||
		submitOverlayVisible ||
		notAvailableActionTriggered;

	return (
		<div css={style(cartStyles)}>
			{cartIncludesRecurring(cartStatus) && <SubscriptionTermsCheckbox />}
			{/* only shows in UI when regulations exist */}
			{!isSmallScreen && paymentMethod !== PaymentMethods.APPLE_PAY
				? regulations.map((regulation) => (
						<ConsentCheckbox
							key={regulation.value}
							regulation={regulation}
							setRegulations={setRegulations}
						/>
					))
				: null}
			<Typography variant="body2" sx={{ marginBottom: '12px' }} id="CB-terms-disclaimer">
				<Trans
					i18nKey={`common:legal.terms-dynamic-1`}
					components={[
						<a
							key="0"
							href="https://support.clickbank.com/hc/en-us/articles/6576730308628-ClickBank-Terms-of-Sale"
							target="_blank"
							rel="noopener noreferrer"
						/>,
					]}
					values={{ buttonText: legalTextMap[paymentMethod] }}
				/>
			</Typography>
			{paymentMethod === PaymentMethods.APPLE_PAY ? (
				<button
					id="apple-pay-submit-button"
					lang={selectedLanguage}
					type="submit"
					className="apple-pay-button apple-pay-button-black"
					disabled={disableSubmit}
				/>
			) : paymentMethod === PaymentMethods.PAZE ? (
				<>
					<Button
						fullWidth
						size="large"
						type="submit"
						className="CB-button-pay paze-button"
						disabled={disableSubmit || !pazeData.checkoutResponse}
						id="paze-submit-button"
					>
						{buttonText}
					</Button>
				</>
			) : (
				<Button
					fullWidth
					size="large"
					type="submit"
					className={`${
						paymentMethod === PaymentMethods.PAYPAL ? 'paypal-button' : ''
					} CB-button-pay`}
					disabled={disableSubmit}
					id="CB-button-pay"
				>
					{buttonText}
				</Button>
			)}
			<div className="security-logos-container">
				<SecurityLogos />
			</div>
		</div>
	);
};

export default SubmitButton;
