import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useShallow } from 'zustand/react/shallow';

import Cart from '@/components/cart/Cart';
import CustomerInfoBlock from '@/components/formBlocks/CustomerInfoBlock';
import ShippingAddress from '@/components/formBlocks/ShippingAddress';
import { getZoneStyles } from '@/components/mainContent/contentStyle';
import Payment from '@/components/payment/Payment';
import { useStore } from '@/state/stores';
import { cbNeutral, defaultPanelBorderColor, defaultPanelBoxShadow } from '@/theme';
import { Layouts } from '@/utils/enums';
import { isTemplateJoined } from '@/utils/helpers';

const style = (template) => {
	const { backgroundColor, boxShadow, borderSize, borderColor, borderRadius } =
		getZoneStyles(template, 'cart') || {};
	const zonesAreConnected = isTemplateJoined(template);
	const border = zonesAreConnected
		? `${borderSize || '0px'} solid ${borderColor || defaultPanelBorderColor}`
		: 'none';
	const connectedBoxShadow = boxShadow || defaultPanelBoxShadow;
	return css`
		.zone-container {
			display: flex;
			flex-direction: column;
			height: 100%;
			> * {
				flex-grow: 0;
				flex-basis: auto;
			}
		}
		.column.lower-left {
			padding: 0 8px 0 0;
		}
		.panel-wrapper {
			background-color: ${backgroundColor || cbNeutral[1000]};
			box-shadow: ${connectedBoxShadow};
			border: ${border};
			border-radius: ${borderRadius && zonesAreConnected ? borderRadius : '0px'};
			overflow: hidden;
			flex-grow: 1;
		}
	`;
};

const ConnectedStandardLayout = ({ shouldDisplayBumps, bumpList }) => {
	const { isShippable, template } = useStore(
		useShallow((state) => ({
			isShippable: state.isShippable,
			template: state.template,
			paymentMethod: state.paymentMethod,
		})),
	);

	const isOriginalColumnOrder = template.layout !== Layouts.JOINED_INPUT_RIGHT_BUTTON_RIGHT;
	// If below is true then bumps should be rendered in CartFooter.js
	const showBumpsInCart = [Layouts.STANDARD, Layouts.JOINED].includes(template.layout);

	return (
		<Grid css={style(template)} className="main-zone-background CB-background" container>
			<Grid container className="zone-container">
				<Grid
					container
					item
					className="panel-wrapper CB-form"
					id="CB-connected-form-wrapper"
				>
					<Grid
						item
						sm={6}
						className={`column ${
							isOriginalColumnOrder ? 'CB-column-left' : 'CB-column-right'
						}`}
						sx={{
							order: isOriginalColumnOrder ? 'unset' : 2,
						}}
					>
						<Grid container direction="column" wrap="nowrap">
							<CustomerInfoBlock />
							<Payment
								bumpList={bumpList}
								showBumpsInPaymentSection={
									shouldDisplayBumps && !showBumpsInCart && !isShippable
								}
							/>
							{isShippable && (
								<ShippingAddress
									bumpList={bumpList}
									showBumpsInShippingSection={
										shouldDisplayBumps && !showBumpsInCart
									}
								/>
							)}
						</Grid>
					</Grid>
					<Grid
						item
						sm={6}
						className={`column ${
							isOriginalColumnOrder ? 'CB-column-right' : 'CB-column-left'
						}`}
					>
						<Grid container direction="column" wrap="nowrap">
							<Cart bumpList={bumpList} shouldDisplayBumps={shouldDisplayBumps} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

ConnectedStandardLayout.propTypes = {
	shouldDisplayBumps: PropTypes.bool,
	bumpList: PropTypes.array,
};

export default ConnectedStandardLayout;
