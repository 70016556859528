import { css } from '@emotion/react';
import { useContext } from 'react';

import { MediaQueryContext } from '@/state/MediaQueryContext';
import {
	cbNegative,
	checkoutTheme,
	defaultBodyFontColor,
	defaultButtonBackgroundColor,
	defaultHeadingFontColor,
	defaultHeadingFontWeight,
	defaultInputBackgroundColor,
	defaultInputBorderColor,
	defaultInputFocusBorderColor,
	defaultInputLabelFontWeight,
	defaultInputValueFontWeight,
	defaultPanelBorderColor,
	defaultPanelLabelColor,
	defaultSecureBannerBackgroundColor,
	defaultSecureBannerFontColor,
	fontWeightBold,
	layoutMaxWidth,
	primaryFont,
	templateBackgroundColor,
} from '@/theme';
import { Layouts } from '@/utils/enums';
import { colorIsAbsentOrTransparent, isTemplateJoined } from '@/utils/helpers';

export const getZoneStyles = (template, id) => {
	if (!template.name) return;
	const zone = template.zones.find((zone) => zone.id === id);
	return zone?.style?.attributes;
};

export const getInputStyles = (body) => {
	return `
		width: 100%;

		// HELPER TEXT STYLES
		.MuiFormHelperText-root {
    		color: ${body?.fontColor || defaultBodyFontColor};
			${body?.fontFamily ? `font-family: ${body.fontFamily}, sans-serif;` : ''}
			${body?.fontWeight ? `font-weight: ${body.fontWeight};` : ''}
			
			&.Mui-error {
				color: ${cbNegative[280]};
			}
  		}

		// LABEL STYLES
		.MuiFormLabel-root {
			${body?.fontColor ? `color: ${body.fontColor};` : ''}
			${body?.fontFamily ? `font-family: ${body.fontFamily}, sans-serif;` : ''}

			&.Mui-focused {
				${body?.inputBorderSelectedColor ? `color: ${body.inputBorderSelectedColor};` : ''}
			}
			&.Mui-error {
				color: ${cbNegative[280]};
			}
		}

		.MuiOutlinedInput-root {
			// BORDER STYLES
			&.MuiInputBase-root, .MuiInputBase-input {
				border-radius: ${body?.inputBorderRadius ? body.inputBorderRadius : '4px'};
			}
			&.MuiInputBase-root {
				background-color: ${body?.inputBackgroundColor || defaultInputBackgroundColor};
				&.Mui-focused fieldset,
				&.Mui-focused:hover fieldset, 
				fieldset {
					${body?.inputBorderSelectedColor ? `border-color: ${body.inputBorderSelectedColor};` : ''}
					border-width: ${body?.inputBorderWidth || '1px'};
				}

				&:not(.Mui-error,.Mui-focused) fieldset {
					${body?.inputBorderColor ? `border-color: ${body.inputBorderColor};` : ''}
				}
			}

			// VALUE STYLES
			.MuiInputBase-input {
				${body?.fontColor ? `color: ${body.fontColor};` : ''}
				${body?.fontWeight ? `font-weight: ${body.fontWeight};` : ''}
				${body?.fontFamily ? `font-family: ${body.fontFamily}, sans-serif;` : ''}

				&.Mui-disabled {
					${body?.fontColor ? `-webkit-text-fill-color: ${body.fontColor};` : ''}
					opacity: 0.5;
				}
			}

			&.Mui-error {
				&.Mui-focused {
					.MuiInputBase-input {
						${body?.fontColor ? `color: ${body.fontColor};` : ''}
					}
				}
				.MuiInputBase-input {
					color: ${cbNegative[280]};
				}
				fieldset {
					border-color: ${cbNegative[280]};
				}
			}
		}

		// ICON STYLES
		.MuiNativeSelect-icon {
			color: ${body?.fontColor ? `${body.fontColor};` : defaultBodyFontColor};
		}

		.MuiOutlinedInput-root.Mui-error .MuiNativeSelect-icon {
			color: ${body?.fontColor ? `${body.fontColor};` : `${cbNegative[280]}`}
		}
	`;
};

export const getHeaderStyles = (header) => {
	const headerFontFamily = header?.fontFamily ? `${header.fontFamily}, sans-serif` : 'inherit';
	const headingHasBackgroundColor = !colorIsAbsentOrTransparent(header?.backgroundColor);
	return `
	.heading {
		font-weight: ${header?.fontWeight || defaultHeadingFontWeight};
		color: ${header?.fontColor || defaultHeadingFontColor};
		width: 100%;
		font-family: ${headerFontFamily};
		margin-bottom: 12px;
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
		hyphens: auto;
		${headingHasBackgroundColor ? 'padding: 8px;' : ''}
		background-color: ${header?.backgroundColor || 'transparent'};
	}`;
};

export const doesZoneHaveWidgets = (zones, id) => {
	const foundZone = zones.find((zone) => zone.id === id);
	return Boolean(foundZone?.widgets?.length);
};

export const customInputStyles = (body) => {
	const bodyFontFamily = body?.fontFamily ? `${body.fontFamily}, sans-serif` : 'inherit';

	return css`
		.MuiFormLabel-root {
			color: ${body?.fontColor || defaultPanelLabelColor};
			font-family: ${bodyFontFamily};
			font-weight: ${body?.fontWeight || defaultInputLabelFontWeight};

			&.Mui-focused {
				color: ${body?.fontColor || defaultButtonBackgroundColor};
			}

			&.Mui-error {
				color: ${cbNegative[500]};
			}
		}

		.MuiOutlinedInput-root.MuiInputBase-root:not(.Mui-error) {
			border-radius: ${body?.inputBorderRadius || '4px'};
			color: ${body?.fontColor || defaultPanelLabelColor};

			fieldset {
				border-color: ${body?.inputBorderColor || defaultInputBorderColor};
			}

			&.Mui-focused {
				fieldset {
					border-color: ${body?.inputBorderSelectedColor || defaultButtonBackgroundColor};
				}
			}
		}

		.MuiOutlinedInput-root.MuiInputBase-root.Mui-error {
			border-radius: ${body?.inputBorderRadius || '4px'};
			color: ${cbNegative[500]};
			fieldset {
				border-color: ${cbNegative[500]};
			}
			&.Mui-focused {
				fieldset {
					border-color: ${body?.inputBorderSelectedColor || defaultInputFocusBorderColor};
				}
			}
		}

		.MuiNativeSelect-select {
			color: ${body?.fontColor || defaultPanelLabelColor};
			font-weight: ${body?.fontWeight || defaultInputValueFontWeight};
			font-family: ${bodyFontFamily};
			&.Mui-disabled {
				-webkit-text-fill-color: ${body?.fontColor || 'inherit'};
				opacity: 0.5;
			}
		}
	`;
};

export const contentStyle = (template) => {
	const headerStyles = getZoneStyles(template, 'header');
	const mainStyles = getZoneStyles(template, 'leftColumnTop');
	const footerStyles = getZoneStyles(template, 'footer');
	const cartStyles = getZoneStyles(template, 'cart');
	const { borderSize, borderColor, borderRadius } = cartStyles || {};
	const zonesAreConnected = isTemplateJoined(template);

	const isBorderTransparent = borderColor?.length > 7 && borderColor?.slice(-2) === '00';
	const hideBorder = !borderColor || isBorderTransparent;
	const borderWidth = borderSize || '1px';
	const borderColorValue = borderColor || defaultPanelBorderColor;
	const border = hideBorder ? 'none' : `${borderWidth} solid ${borderColorValue}`;

	const isDefault = !template.name;

	const headerZoneHasWidgets = !isDefault && doesZoneHaveWidgets(template.zones, 'header');
	const footerZoneHasWidgets = !isDefault && doesZoneHaveWidgets(template.zones, 'footer');
	const { isTwoColumnLayout } = useContext(MediaQueryContext);

	const isOriginalColumnOrder =
		template.layout !== Layouts.STANDARD_INPUT_RIGHT_BUTTON_RIGHT &&
		template.layout !== Layouts.JOINED_INPUT_RIGHT_BUTTON_RIGHT;

	return css`
		background: ${templateBackgroundColor};
		flex-grow: 1;
		display: flex;

		${checkoutTheme.breakpoints.down('sm')} {
			padding: 0;
			border: ${border}
			border-radius: ${borderRadius};
		}

		.container {
			${checkoutTheme.breakpoints.down('sm')} {
				padding: 0;
			}

			${checkoutTheme.breakpoints.up('sm')} {
				padding: 0;
				max-width: none;
			}

			> #checkoutForm {
				height: 100%;

				> .layout-factory-wrapper {
					height: 100%;
				}
			}
		}

		.layout-factory-wrapper {
			justify-content: center;
		}

		.layout-factory-inner-wrapper {
			${checkoutTheme.breakpoints.up('sm')} {
				align-content: flex-start;
				display: flex;
				flex-direction: column;
			}
		}

		.secure-checkout-wrapper {
			background: ${mainStyles?.backgroundColor || 'transparent'};
			width: 100%;
		}

		.secure-checkout-padding-container {
			padding-top: ${headerZoneHasWidgets ? '0' : '4px'};
			max-width: ${layoutMaxWidth};
			margin: ${zonesAreConnected ? '0 auto' : '0 auto 8px'};
			${checkoutTheme.breakpoints.down('sm')} {
				margin: 0px;
				padding: 0px;
			}
		}

		.secure-checkout-container {
			display: flex;
			width: 100%;
			justify-content: center;
			background: ${
				template?.style?.attributes?.secureBanner?.backgroundColor ||
				defaultSecureBannerBackgroundColor
			};
			color: ${template?.style?.attributes?.secureBanner?.fontColor || defaultSecureBannerFontColor};
			height: 40px;
			align-items: center;

			.MuiTypography-body2 {
				font-family: ${template?.style?.attributes?.secureBanner?.fontFamily || primaryFont};
				font-weight: ${template?.style?.attributes?.secureBanner?.fontWeight || fontWeightBold};
			}

			svg {
				margin-right: 8px;
				font-size: 1rem;
			}
		}

		.small-screen-container .main-zone-background {
			${checkoutTheme.breakpoints.down('sm')} {
				display: block;
			}
		}

		.main-zone-background .column {
			padding: 0 0 0 8px;

			${checkoutTheme.breakpoints.up('sm')} {
				padding-left: ${isOriginalColumnOrder ? '8px' : '0'};
				padding-right: ${isOriginalColumnOrder ? '0' : '8px'};
			}

			${checkoutTheme.breakpoints.down('sm')} {
				padding: 0;
			}

			${checkoutTheme.breakpoints.up('md')} {
				&.not-approved {
					max-width: 100%;
				}
			}
		}

		.main-zone-background .column:first-of-type {
			padding: ${isOriginalColumnOrder ? '0 8px 0 0' : '0 0 0 8px'};
			${checkoutTheme.breakpoints.down('sm')} {
				padding: 0;
			}

			${checkoutTheme.breakpoints.up('md')} {
				&.not-approved {
					max-width: 100%;
				}
			}
		}

		.zone-header,
		.zone-footer {
			width: 100%;
		}

		.not-approved-card {
			border-top: 8px solid ${cbNegative[500]};
			text-align: center;
			width: 100%;
			p {
				font-weight: bold;
			}
		}

		.header-zone-background,
		.main-zone-background,
		.footer-zone-background {
			display: flex;
			justify-content: center;
		}

		.header-zone-background {
			background: ${headerStyles?.backgroundColor || 'transparent'};

			${checkoutTheme.breakpoints.up('sm')} {
				padding: ${!headerZoneHasWidgets ? '0' : '4px 16px 0 16px'};
			}
		}

		.main-zone-background {
			${checkoutTheme.breakpoints.up('sm')} {
				padding: 0 16px 16px 16px;
				padding-top: ${isTwoColumnLayout ? '8px' : '0'};
				flex: 1;
				display: flex;
				align-items: flex-start;
			}
			background: ${mainStyles?.backgroundColor || 'transparent'};
		}

		.footer-zone-background {
			background: ${footerStyles?.backgroundColor || 'transparent'};

			${checkoutTheme.breakpoints.up('sm')} {
				padding: ${footerZoneHasWidgets ? '0 16px 16px 16px' : '0'};
			}
		}

		.zone-container {
			max-width: ${layoutMaxWidth};
		}
	`;
};
