import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { getVisibilityStyle } from './layoutFactoryConstants';
import { defaultBorderColor, initialWidgetPadding } from '@/theme';
import { WidgetVisibility } from '@/utils/enums';

const textStyle = ({
	backgroundColor,
	borderColor,
	borderRadius,
	borderSize,
	borderStyle,
	padding,
}) => {
	const border = `${borderSize || '0px'} ${borderStyle || 'solid'}
	${borderColor || defaultBorderColor}`;

	return css`
		box-sizing: border-box;
		padding: ${padding || initialWidgetPadding};
		background-color: ${backgroundColor || 'transparent'};
		border: ${border};
		border-radius: ${borderRadius || '0px'};

		> :first-child {
			margin-top: 0;
			padding-top: 0;
		}
		> :last-child {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	`;
};

const TextWidget = ({ visibility, text, style, id }) => {
	return (
		<div
			className="CB-text-module"
			id={`CB-text-module-${id}`}
			css={[getVisibilityStyle(visibility), textStyle(style?.attributes || {})]}
			dangerouslySetInnerHTML={{ __html: text }}
		></div>
	);
};

TextWidget.propTypes = {
	zoneId: PropTypes.string,
	visibility: PropTypes.arrayOf(
		PropTypes.oneOf([
			WidgetVisibility.MOBILE,
			WidgetVisibility.MOBILE_2,
			WidgetVisibility.TABLET,
			WidgetVisibility.TABLET_2,
			WidgetVisibility.DESKTOP,
			WidgetVisibility.DESKTOP_2,
		]),
	),
	text: PropTypes.string.isRequired,
	style: PropTypes.shape({
		attributes: PropTypes.shape({
			backgroundColor: PropTypes.string,
			borderColor: PropTypes.string,
			borderStyle: PropTypes.string,
			borderRadius: PropTypes.string,
			borderSize: PropTypes.string,
			padding: PropTypes.string,
		}),
	}),
	id: PropTypes.string.isRequired,
};

export default TextWidget;
