import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { lazy } from 'react';

import { getVisibilityStyle } from './layoutFactoryConstants';
import { defaultBorderColor, initialWidgetPadding } from '@/theme';
import { WidgetVisibility } from '@/utils/enums';
import { isPreview } from '@/utils/helpers';

const flexDirections = {
	top: 'column',
	right: 'row-reverse',
	bottom: 'column-reverse',
	left: 'row',
};

const mapPositionToTextPaddingKey = {
	top: 'padding-top',
	right: 'padding-right',
	bottom: 'padding-bottom',
	left: 'padding-left',
};

const Metadata = isPreview ? lazy(() => import('./ImagePreviewMetadata')) : null;

const imageCaptionStyles = ({
	backgroundColor,
	borderColor,
	borderRadius,
	borderSize,
	borderStyle,
	padding,
	imageWidth,
	imagePosition,
	imageBorderRadius,
}) => {
	const border = `${borderSize || '0px'} ${borderStyle || 'solid'}
	${borderColor || defaultBorderColor}`;
	return css`
		border: ${border};
		padding: ${padding || initialWidgetPadding};
		background-color: ${backgroundColor || 'transparent'};
		border-radius: ${borderRadius || '0'};
		display: flex;
		flex-direction: ${flexDirections[imagePosition]};
		align-items: stretch;
		width: 100%;
		position: relative;

		.image-container {
			width: ${imageWidth};
			position: relative;
		}

		img {
			width: 100%;
			display: block;
			align-self: center;
			border-radius: ${imageBorderRadius || '0'};
		}

		> .text-content {
			${mapPositionToTextPaddingKey[imagePosition]}: 16px;
			flex-grow: 1;

			> :first-child {
				margin-top: 0;
				padding-top: 0;
			}
			> :last-child {
				margin-bottom: 0;
				padding-bottom: 0;
			}
		}
	`;
};

const ImageCaptionWidget = ({ visibility, style, text, image, id }) => {
	const imageMetadata = {};
	const { imageAlt, imageTitle, imageUrl } = image || {};

	if (imageAlt) {
		imageMetadata.alt = imageAlt;
	}
	if (imageTitle) {
		imageMetadata.title = imageTitle;
	}
	return imageUrl ? (
		<div
			css={[getVisibilityStyle(visibility), imageCaptionStyles(style?.attributes || {})]}
			className="CB-image-caption-module"
			id={`CB-image-caption-module-${id}`}
		>
			<div className="image-container">
				<img src={imageUrl} {...imageMetadata} />
				{isPreview && <Metadata imageAlt={imageAlt} imageTitle={imageTitle} />}
			</div>
			<div dangerouslySetInnerHTML={{ __html: text }} className="text-content" />
		</div>
	) : null;
};

ImageCaptionWidget.propTypes = {
	zoneId: PropTypes.string,
	visibility: PropTypes.arrayOf(
		PropTypes.oneOf([
			WidgetVisibility.MOBILE,
			WidgetVisibility.MOBILE_2,
			WidgetVisibility.TABLET,
			WidgetVisibility.TABLET_2,
			WidgetVisibility.DESKTOP,
			WidgetVisibility.DESKTOP_2,
		]),
	),
	text: PropTypes.string.isRequired,
	style: PropTypes.shape({
		attributes: PropTypes.shape({
			backgroundColor: PropTypes.string,
			borderColor: PropTypes.string,
			borderStyle: PropTypes.string,
			borderRadius: PropTypes.string,
			borderSize: PropTypes.string,
			padding: PropTypes.string,
		}),
	}),
	image: PropTypes.shape({
		imageKey: PropTypes.string,
		imageTitle: PropTypes.string,
		imageUrl: PropTypes.string,
		imageAlt: PropTypes.string,
	}),
	id: PropTypes.string.isRequired,
};

export default ImageCaptionWidget;
