import { Autocomplete, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import CBTextField from '@/components/common/CBTextField';
import { useFormStore, useStore } from '@/state/stores';
import {
	doesCountryHaveProvinces,
	doesCountryHaveStates,
	sortObjectsByLabelString,
} from '@/utils/helpers';
import { isInputRequired } from '@/utils/requiredInputs';
import { provinces, states } from '@/utils/statesAndProvinces';

const CityAndState = (props) => {
	const { accessor, renderInBilling } = props;
	const { t } = useTranslation('checkout');

	const { setFormValue, city, state, countryCode } = useFormStore(
		useShallow((state) => ({
			formData: state.formData,
			setFormValue: state.setFormValue,
			city: state.formData[accessor].city,
			state: state.formData[accessor].state,
			countryCode: state.formData[accessor].countryCode,
		})),
	);

	const { selectedLanguage, cartInitialized, isShippable, cityOptions } = useStore(
		useShallow((state) => ({
			selectedLanguage: state.selectedLanguage,
			cartInitialized: state.cartInitialized,
			isShippable: state.isShippable,
			cityOptions: state.cityOptions[accessor],
		})),
	);

	const [isCityInputFocused, setIsCityInputFocused] = useState(false);
	const [isStateInputFocused, setIsStateInputFocused] = useState(false);

	const hasStates = doesCountryHaveStates(countryCode);
	const hasProvinces = doesCountryHaveProvinces(countryCode);
	const hasStatesOrProvinces = hasStates || hasProvinces;
	const isUSorCA = ['US', 'CA'].includes(countryCode);

	const getStateLabel = () => {
		if (hasStates) {
			return t('field.state-only.label');
		}

		if (hasProvinces) {
			return t('field.province.label');
		}
	};

	const handleSelection = (_, value, name) => {
		setFormValue(name, value.value);
		name === `${accessor}.city` ? setIsCityInputFocused(false) : setIsStateInputFocused(false);
	};

	const countryIsIrelandOrUK = ['IE', 'GB'].includes(countryCode);

	const shouldShowCity =
		(isShippable && countryCode !== 'US') ||
		(isShippable && cityOptions) ||
		(cityOptions && cityOptions.length > 1);

	const cityGridWidth = () => {
		if (hasStatesOrProvinces && selectedLanguage === 'es') {
			return 4.5;
		}
		if (hasStatesOrProvinces) {
			return 5.5;
		}
		return 6;
	};

	const stateOptions = () => {
		if (countryCode === 'US') {
			return states;
		}
		if (countryCode === 'CA') {
			return provinces;
		}
		return [];
	};

	return renderInBilling ? (
		<>
			{shouldShowCity ? (
				<Grid item xs={12} lg={hasStatesOrProvinces ? 6 : 12}>
					<Autocomplete
						options={
							cityOptions?.length > 1 ? sortObjectsByLabelString(cityOptions) : []
						}
						freeSolo
						disableClearable
						value={city}
						onChange={(_, value) => handleSelection(_, value, `${accessor}.city`)}
						isOptionEqualToValue={(option, value) => option.value === value}
						onFocus={() => setIsCityInputFocused(true)}
						onBlur={() => {
							setIsCityInputFocused(false);
						}}
						open={isCityInputFocused}
						renderInput={(params) => (
							<CBTextField
								{...params}
								name={`${accessor}.city`}
								label={
									countryIsIrelandOrUK
										? t('field.town.label')
										: t('field.city.label')
								}
								required={true}
								addressAccessor={accessor}
								showPlaceholder={!cartInitialized}
							/>
						)}
					/>
				</Grid>
			) : null}
		</>
	) : (
		<>
			<Grid
				item
				xs={12}
				lmd={cityGridWidth()}
				className={hasStatesOrProvinces && selectedLanguage === 'es' ? 'less-padding' : ''}
			>
				<Autocomplete
					options={cityOptions?.length > 1 ? sortObjectsByLabelString(cityOptions) : []}
					freeSolo
					disableClearable
					value={city}
					onChange={(_, value) => handleSelection(_, value, `${accessor}.city`)}
					isOptionEqualToValue={(option, value) => option.value === value}
					onFocus={() => setIsCityInputFocused(true)}
					onBlur={() => {
						setIsCityInputFocused(false);
					}}
					open={isCityInputFocused}
					renderInput={(params) => (
						<CBTextField
							{...params}
							name={`${accessor}.city`}
							label={
								countryIsIrelandOrUK ? t('field.town.label') : t('field.city.label')
							}
							required={true}
							addressAccessor={accessor}
							showPlaceholder={!cartInitialized}
						/>
					)}
				/>
			</Grid>
			{hasStatesOrProvinces ? (
				<Grid
					item
					xs={12}
					lmd={3}
					className={selectedLanguage === 'es' ? 'less-padding' : ''}
				>
					<Autocomplete
						options={stateOptions()}
						freeSolo={!isUSorCA}
						popupIcon={null}
						disableClearable
						value={state}
						onChange={(_, value) => handleSelection(_, value, `${accessor}.state`)}
						isOptionEqualToValue={(option, value) => {
							return option.value === value;
						}}
						filterOptions={(options, state) => {
							return options.filter(
								(option) =>
									option.value &&
									option.label.includes(state.inputValue.toUpperCase()),
							);
						}}
						onFocus={() => setIsStateInputFocused(true)}
						onBlur={() => {
							setIsStateInputFocused(false);
						}}
						open={isStateInputFocused}
						renderInput={(params) => (
							<CBTextField
								{...params}
								name={`${accessor}.state`}
								label={getStateLabel()}
								required={isInputRequired('state', countryCode)}
								addressAccessor={accessor}
								showPlaceholder={!cartInitialized}
								updateOn={isUSorCA ? 'none' : 'change'}
							/>
						)}
					/>
				</Grid>
			) : null}
		</>
	);
};

CityAndState.propTypes = {
	accessor: PropTypes.oneOf(['billing', 'shipping']),
	renderInBilling: PropTypes.bool,
};

CityAndState.defaultProps = {
	showPlaceholder: false,
};

export default CityAndState;
