import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import Cart from '@/components/cart/Cart';
import CustomerInfoBlock from '@/components/formBlocks/CustomerInfoBlock';
import ShippingAddress from '@/components/formBlocks/ShippingAddress';
import { getZoneStyles } from '@/components/mainContent/contentStyle';
import Payment from '@/components/payment/Payment';
import { MediaQueryContext } from '@/state/MediaQueryContext';
import { useStore } from '@/state/stores';
import { defaultPanelBorderColor, defaultPanelBoxShadow } from '@/theme';
import { Layouts } from '@/utils/enums';

const StandardLayout = ({ bumpList, shouldDisplayBumps }) => {
	const { isTwoColumnLayout } = useContext(MediaQueryContext);

	const isShippable = useStore((state) => state.isShippable);
	const template = useStore((state) => state.template);
	const cartStyles = getZoneStyles(template, 'cart');
	const { borderRadius, borderSize, borderColor, boxShadow } = cartStyles || {};
	const isBorderTransparent = borderColor?.length > 7 && borderColor?.slice(-2) === '00';
	const hideBorder = !borderColor || isBorderTransparent;
	const borderWidth = borderSize || '1px';
	const borderColorValue = borderColor || defaultPanelBorderColor;
	const border = hideBorder ? 'none' : `${borderWidth} solid ${borderColorValue}`;

	const isOriginalColumnOrder = template.layout !== Layouts.STANDARD_INPUT_RIGHT_BUTTON_RIGHT;

	// If below is true then bumps should be rendered in CartFooter.js
	const showBumpsInCart = [Layouts.STANDARD, Layouts.JOINED].includes(template.layout);

	return (
		<Grid className="main-zone-background CB-background" container id="CB-main-background">
			<Grid container className="zone-container" id="CB-standard-form-wrapper">
				<Grid
					item
					sm={6}
					className={`column ${
						isOriginalColumnOrder ? 'CB-column-left' : 'CB-column-right'
					}`}
					sx={{
						order: isOriginalColumnOrder ? 'unset' : 2,
					}}
				>
					<Grid
						container
						direction="column"
						wrap="nowrap"
						sx={{
							border: isTwoColumnLayout ? border : '',
							borderRadius: isTwoColumnLayout ? borderRadius : '',
							boxShadow: isTwoColumnLayout ? boxShadow || defaultPanelBoxShadow : '',
							overflow: isTwoColumnLayout ? 'hidden' : '',
						}}
					>
						<CustomerInfoBlock />
						<Payment
							bumpList={bumpList}
							showBumpsInPaymentSection={
								shouldDisplayBumps && !showBumpsInCart && !isShippable
							}
						/>
						{isShippable && (
							<ShippingAddress
								bumpList={bumpList}
								showBumpsInShippingSection={shouldDisplayBumps && !showBumpsInCart}
							/>
						)}
					</Grid>
				</Grid>
				<Grid
					item
					sm={6}
					className={`column ${
						isOriginalColumnOrder ? 'CB-column-right' : 'CB-column-left'
					}`}
				>
					<Grid container direction="column" wrap="nowrap">
						<Cart bumpList={bumpList} shouldDisplayBumps={shouldDisplayBumps} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

StandardLayout.propTypes = {
	bumpList: PropTypes.array,
	shouldDisplayBumps: PropTypes.bool,
};

export default StandardLayout;
