import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { getVisibilityStyle } from './layoutFactoryConstants';
import { defaultDividerColor, initialDividerWidgetPadding } from '@/theme';
import { WidgetVisibility } from '@/utils/enums';

const dividerStyle = ({ borderSize, borderStyle, borderColor, padding }) => {
	return css`
		box-sizing: border-box;
		width: 100%;
		hr {
			margin: ${padding || initialDividerWidgetPadding};
			border: ${borderSize || '1px'} ${borderStyle || 'solid'}
				${borderColor || defaultDividerColor};
			border-bottom: none;
		}
	`;
};

const Divider = (props) => {
	return (
		<div
			css={[
				dividerStyle(props.style?.attributes || {}),
				getVisibilityStyle(props.visibility),
			]}
			className="CB-divider-module"
			id={`CB-divider-module-${props.id}`}
		>
			<hr />
		</div>
	);
};

export default Divider;

Divider.propTypes = {
	style: PropTypes.shape({
		attributes: PropTypes.shape({
			size: PropTypes.string,
			borderStyle: PropTypes.string,
			color: PropTypes.string,
			padding: PropTypes.string,
		}),
	}),
	visibility: PropTypes.arrayOf(
		PropTypes.oneOf([
			WidgetVisibility.MOBILE,
			WidgetVisibility.MOBILE_2,
			WidgetVisibility.TABLET,
			WidgetVisibility.TABLET_2,
			WidgetVisibility.DESKTOP,
			WidgetVisibility.DESKTOP_2,
		]),
	),
	id: PropTypes.string.isRequired,
};
