import { css } from '@emotion/react';
import { Edit, InfoOutlined } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import CreditCardIcons from './CreditCardIcons';
import SecurityCodeModal from './SecurityCodeModal';
import TokenExInput from './TokenExInput';
import TokenExInputPlaceHolder from './TokenExInputPlaceholder';
import { applePayLogo, payPalColor, pazeLogo } from '@/assets';
import CBTextField from '@/components/common/CBTextField';
import DigitalAddress from '@/components/formBlocks/DigitalAddress';
import {
	getHeaderStyles,
	getInputStyles,
	getZoneStyles,
} from '@/components/mainContent/contentStyle';
import { useFormStore, useStore } from '@/state/stores';
import {
	cbPrimary,
	defaultBodyFontColor,
	defaultDividerColor,
	defaultInputBorderColor,
	defaultInputFocusBorderColor,
	fontWeightMedium,
} from '@/theme';
import { Layouts, PaymentMethods } from '@/utils/enums';
import { isTemplateJoined } from '@/utils/helpers';
import { pazeCheckout, resetPazeData } from '@/utils/pazeHelpers';

const accordionStyles = (template) => {
	const paymentStyles = getZoneStyles(template, 'payment');
	const { body, header } = paymentStyles || {};
	const inputStyles = getInputStyles(body);
	const headerStyles = getHeaderStyles(header);
	const isJoined = isTemplateJoined(template);
	const hasWidgetBelow = Boolean(
		template.zones.find((zone) => zone.id === 'leftColumnBottom')?.widgets?.length,
	);
	const hideLastBorder = template.layout === Layouts.STANDARD && !hasWidgetBelow;

	return css`
		padding-left: ${isJoined ? '24px' : 0};
		padding-right: ${isJoined ? '24px' : 0};
		.credit-card-panel {
			display: flex;
			align-items: center;
		}

		.security-code-help-icon {
			cursor: pointer;
			fill: ${body?.fontColor || defaultBodyFontColor};
		}

		.token-ex-grid {
			position: relative;
		}

		.payment-summary {
			display: flex;
			align-items: center;
			font-weight: 600;
			color: ${body?.fontColor || 'inherit'};
			font-family: ${body?.fontFamily || 'inherit'};
		}

		.MuiCardContent-root {
			padding: 0;
		}

		.MuiAccordion-root:first-of-type {
			border-radius: 0;
		}

		.MuiAccordion-root.Mui-expanded {
			margin-bottom: 0;
			margin-top: 0;
		}

		.MuiAccordion-root {
			box-shadow: none;
			border-radius: 0;
			border-top: 1px solid ${body?.dividerColor || defaultDividerColor};
			background: transparent;
		}

		.MuiAccordion-root:last-of-type {
			border-bottom: ${hideLastBorder
				? 'none'
				: `1px solid ${body?.dividerColor || defaultDividerColor}`};
		}

		.MuiAccordionSummary-root {
			padding: 16px 24px;
			padding-left: ${isJoined ? 0 : ''};
			padding-right: ${isJoined ? 0 : ''};
		}

		.MuiAccordionSummary-content {
			margin: 0;
		}

		.MuiAccordionDetails-root {
			padding-top: 0;
			padding-left: ${isJoined ? 0 : ''};
			padding-right: ${isJoined ? 0 : ''};
		}

		.MuiCollapse-root {
			padding-left: ${isJoined ? 0 : '8px'};
			padding-right: ${isJoined ? 0 : '8px'};
		}

		.MuiRadio-root {
			padding: 0;
			margin-right: 5px;
			color: ${body?.inputBorderColor || defaultInputBorderColor};
		}

		.MuiRadio-root.Mui-checked {
			color: ${body?.inputBorderSelectedColor || defaultInputFocusBorderColor};
		}

		.paze-edit {
			margin-left: auto;
			display: flex;
			align-items: center;
			color: ${cbPrimary[500]};

			svg {
				margin-right: 8px;
			}
		}

		.paze-accordion-details {
			display: flex;
			align-items: center;

			.paze-card-details,
			.paze-card-descriptor {
				margin-left: 8px;
			}
			.paze-card-descriptor {
				font-weight: ${fontWeightMedium};
			}

			img {
				height: 46px;
				width: 73px;
			}
		}

		${headerStyles}
		${inputStyles}
	`;
};

const PayMethodAccordions = () => {
	const clearFieldError = useFormStore((state) => state.clearFieldError);
	const getFormData = useFormStore((state) => state.getFormData);
	const {
		setIsCartUpdating,
		calculateCartAddressVars,
		setCalculateCartAddressVars,
		setErrorData,
		pazeData,
		setPazeData,
		applePayData,
		paymentMethod,
		setPaymentMethod,
		payPalEnabled,
		isInvalidProductInCart,
		isSiteActivated,
		cartInitialized,
		isShippable,
		showTokenExPlaceholder,
		template,
		setFormServerErrorInfo,
		countdownTimerHasExpired,
		orderTotals,
		selectedCurrency,
	} = useStore(
		useShallow((state) => ({
			calculateCartAddressVars: state.calculateCartAddressVars,
			setCalculateCartAddressVars: state.setCalculateCartAddressVars,
			setIsCartUpdating: state.setIsCartUpdating,
			setErrorData: state.setErrorData,
			setPazeData: state.setPazeData,
			pazeData: state.pazeData,
			applePayData: state.applePayData,
			paymentMethod: state.paymentMethod,
			setPaymentMethod: state.setPaymentMethod,
			payPalEnabled: state.payPalEnabled,
			isInvalidProductInCart: state.isInvalidProductInCart,
			isSiteActivated: state.isSiteActivated,
			cartInitialized: state.cartInitialized,
			isShippable: state.isShippable,
			showTokenExPlaceholder: state.showTokenExPlaceholder,
			template: state.template,
			setFormServerErrorInfo: state.setFormServerErrorInfo,
			countdownTimerHasExpired: state.countdownTimerHasExpired,
			orderTotals: state.orderTotals,
			selectedCurrency: state.selectedCurrency,
		})),
	);
	const mergeFormData = useFormStore((state) => state.mergeFormData);
	const formIsValid = useFormStore((state) => state.formIsValid);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
	const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);
	const { t } = useTranslation('checkout');
	const [shouldAllowAnimation, setShouldAllowAnimation] = useState(cartInitialized);
	const animationTimeoutRef = useRef();
	const disableAccordionChange =
		countdownTimerHasExpired && template.countdownTimer.actionType === 'NOT_AVAILABLE';

	useEffect(() => {
		if (!cartInitialized || shouldAllowAnimation || animationTimeoutRef.current) {
			return;
		}
		animationTimeoutRef.current = setTimeout(() => {
			setShouldAllowAnimation(true);
		}, 200);
	}, [cartInitialized, shouldAllowAnimation]);

	const handlePaymentMethodChange = (selectedPaymentMethod) => {
		if (disableAccordionChange) {
			return;
		}
		if (selectedPaymentMethod === PaymentMethods.APPLE_PAY) {
			clearFieldError('email');
			clearFieldError('phone');
		}
		if (paymentMethod !== selectedPaymentMethod) {
			setFormServerErrorInfo(null);
		}
		setPaymentMethod(selectedPaymentMethod);
	};

	const handlePazeEdit = async (newPazeData) => {
		const { email } = getFormData();
		pazeCheckout(
			newPazeData,
			setPazeData,
			handlePaymentMethodChange,
			setErrorData,
			email,
			setIsCartUpdating,
			calculateCartAddressVars,
			setCalculateCartAddressVars,
			orderTotals,
			isShippable,
			selectedCurrency,
			mergeFormData,
			() => formIsValid(true),
		);
	};

	const handlePazePaymentMethodChange = async () => {
		if (disableAccordionChange) {
			return;
		}
		clearFieldError('email');
		clearFieldError('phone');
		setFormServerErrorInfo(null);

		if (!pazeData.checkoutResponse) {
			const { email } = getFormData();
			pazeCheckout(
				pazeData,
				setPazeData,
				handlePaymentMethodChange,
				setErrorData,
				email,
				setIsCartUpdating,
				calculateCartAddressVars,
				setCalculateCartAddressVars,
				orderTotals,
				isShippable,
				selectedCurrency,
				mergeFormData,
				() => formIsValid(true),
			);
		}

		if (pazeData.checkoutResponse && paymentMethod !== PaymentMethods.PAZE) {
			const { maskedCard, shippingAddress } = pazeData.checkoutResponse;
			// shippingAddress returned only when shippingPreference is not NONE.
			const addressForTaxCalculation = shippingAddress
				? shippingAddress
				: maskedCard.billingAddress;

			setIsCartUpdating(true);
			setCalculateCartAddressVars({
				countryCode: addressForTaxCalculation.countryCode,
				zip: addressForTaxCalculation.zip,
				city: addressForTaxCalculation.city,
				state: addressForTaxCalculation.state,
				address1: addressForTaxCalculation.line1,
			});
		}

		setPaymentMethod(PaymentMethods.PAZE);
	};

	const isTestPurchase = isInvalidProductInCart || !isSiteActivated;
	const showApplePayButton = applePayData.showApplePayButton && !isTestPurchase;
	const showPazeButton =
		pazeData.supportedCountries.length > 0 &&
		pazeData.consumerPresent &&
		pazeData.acceptedCCs.length > 0 &&
		(!isShippable ||
			!['preview-prod', 'prod'].includes(process.env.REACT_APP_DEPLOYMENT_TARGET));

	return (
		<div css={accordionStyles(template)} id="CB-payment-method-accordions">
			<Accordion
				expanded={paymentMethod === PaymentMethods.CREDIT}
				onChange={() => handlePaymentMethodChange(PaymentMethods.CREDIT)}
				disableGutters
				sx={{
					'&:before': {
						display: 'none',
					},
				}}
				TransitionProps={{
					unmountOnExit: true,
					...(shouldAllowAnimation ? {} : { timeout: 0 }),
				}}
				className="CB-form CB-payment-method-accordion"
				id="CB-credit-accordion"
			>
				<AccordionSummary
					expandIcon={null}
					aria-controls="credit-card-panel-content"
					id="credit-card-panel-header"
					className="credit-card-panel"
				>
					<Typography className="payment-summary">
						<Radio
							checked={paymentMethod === PaymentMethods.CREDIT}
							style={{ marginRight: 8 }}
						/>
						{t('form.button.credit-card')}
					</Typography>
					{paymentMethod === PaymentMethods.CREDIT && <CreditCardIcons />}
				</AccordionSummary>
				<AccordionDetails>
					<Grid container spacing={2}>
						<Grid item xs={12} className="token-ex-grid">
							{showTokenExPlaceholder ? <TokenExInputPlaceHolder /> : null}
							<TokenExInput />
						</Grid>
						<Grid item xs={12} lmd={6}>
							<CBTextField
								name="expirationDate"
								label={t('field.expiration-date.label')}
								required={true}
								placeholderText="MM/YY"
								inputMode="numeric"
								showPlaceholder={!cartInitialized}
							/>
						</Grid>
						<Grid item xs={12} lmd={6}>
							<CBTextField
								name="securityCode"
								label={t('field.security-code.label')}
								required={true}
								inputMode="numeric"
								showPlaceholder={!cartInitialized}
								InputProps={{
									endAdornment: (
										<Tooltip
											open={isModalOpen}
											onClick={openModal}
											title={
												<SecurityCodeModal
													modalOpen={isModalOpen}
													closeModal={closeModal}
												/>
											}
											componentsProps={{
												tooltip: {
													sx: {
														bgcolor: 'unset',
													},
												},
											}}
										>
											<InputAdornment position="end">
												<InfoOutlined className="security-code-help-icon" />
											</InputAdornment>
										</Tooltip>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<CBTextField
								name="cardHolderName"
								label={t('field.cardholder-name.label')}
								required={true}
								showPlaceholder={!cartInitialized}
							/>
						</Grid>
					</Grid>
					{!isShippable && <DigitalAddress />}
				</AccordionDetails>
			</Accordion>

			{showPazeButton && (
				<Accordion
					expanded={paymentMethod === PaymentMethods.PAZE}
					onChange={handlePazePaymentMethodChange}
					disableGutters
					sx={{
						'&:before': {
							display: 'none',
						},
					}}
					TransitionProps={{
						unmountOnExit: true,
						...(shouldAllowAnimation ? {} : { timeout: 0 }),
					}}
					className="CB-form CB-payment-method-accordion"
					id="CB-paze-accordion"
				>
					<AccordionSummary
						expandIcon={null}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Radio checked={paymentMethod === PaymentMethods.PAZE} />
						<img src={pazeLogo} alt="Paze logo" />
						{pazeData.checkoutResponse &&
							paymentMethod === PaymentMethods.PAZE &&
							!disableAccordionChange && (
								<div
									className="paze-edit"
									onClick={(e) => {
										e.stopPropagation();
										let newPazeData = pazeData;
										if (pazeData.existingCompleteResponse) {
											newPazeData = resetPazeData(setPazeData);
										}
										handlePazeEdit(newPazeData);
									}}
								>
									<Edit />
									<Typography variant="body1">{t('paze.edit')}</Typography>
								</div>
							)}
					</AccordionSummary>
					{pazeData.checkoutResponse && (
						<AccordionDetails className="paze-accordion-details">
							<img
								src={`${pazeData.checkoutResponse.maskedCard.digitalCardData.artUri}`}
							/>
							<div className="paze-details-container">
								<Typography
									variant="body1"
									className="paze-card-descriptor paze-card-details"
								>
									{pazeData.checkoutResponse.maskedCard.paymentCardDescriptor ||
										pazeData.checkoutResponse.maskedCard.paymentCardNetwork}
								</Typography>
								<Typography variant="body1" className="paze-card-details">
									**** {pazeData.checkoutResponse.maskedCard.panLastFour}
								</Typography>
							</div>
						</AccordionDetails>
					)}
				</Accordion>
			)}

			{showApplePayButton && (
				<Accordion
					expanded={paymentMethod === PaymentMethods.APPLE_PAY}
					onChange={() => handlePaymentMethodChange(PaymentMethods.APPLE_PAY)}
					disableGutters
					sx={{
						'&:before': {
							display: 'none',
						},
					}}
					TransitionProps={{
						unmountOnExit: true,
						...(shouldAllowAnimation ? {} : { timeout: 0 }),
					}}
					className="CB-form CB-payment-method-accordion"
					id="CB-apple-accordion"
				>
					<AccordionSummary
						expandIcon={null}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography className="payment-summary">
							<Radio checked={paymentMethod === PaymentMethods.APPLE_PAY} />
							<img
								className="apple-pay-logo"
								src={applePayLogo}
								alt={t('alt-tags.apple-pay-logo')}
								width="53"
								height="23"
							/>
						</Typography>
					</AccordionSummary>
				</Accordion>
			)}
			{payPalEnabled && !isTestPurchase && (
				<Accordion
					expanded={paymentMethod === PaymentMethods.PAYPAL}
					onChange={() => handlePaymentMethodChange(PaymentMethods.PAYPAL)}
					disableGutters
					sx={{
						'&:before': {
							display: 'none',
						},
					}}
					TransitionProps={{
						unmountOnExit: true,
						...(shouldAllowAnimation ? {} : { timeout: 0 }),
					}}
					className="CB-form CB-payment-method-accordion"
					id="CB-paypal-accordion"
				>
					<AccordionSummary
						expandIcon={null}
						aria-controls="panel4a-content"
						id="panel4a-header"
						open={paymentMethod === PaymentMethods.PAYPAL}
					>
						<Typography className="payment-summary">
							<Radio checked={paymentMethod === PaymentMethods.PAYPAL} />
							<img
								className="paypal-logo"
								src={payPalColor}
								alt={t('alt-tags.paypal-logo')}
								width="114"
								height="28"
							/>
						</Typography>
					</AccordionSummary>
					{!isShippable && (
						<AccordionDetails>
							<DigitalAddress />
						</AccordionDetails>
					)}
				</Accordion>
			)}
		</div>
	);
};

export default PayMethodAccordions;
