//https://clickbank.atlassian.net/wiki/spaces/PDM/pages/173984887/New+Order+Form+Error+Management
// submit errors should be displayed in the cart near the pay now button so the user can retry
// any error that prevents submitting an order goes in the error modal
// message = string used to fetch the translated error text from lokalise
// messageType = modal || cart

/**
 * Errors that makes submission impossible default to a product not available message
 * • empty products array
 * • bad/missing vendor
 * • errors on any of the required queries
 * If we need to get more granular with messaging, we can add cases here,
 * as well as add other helper methods above to map to different error messages
 */

export const errorHandler = (data, endpoint) => {
	const error = data?.error;
	const statusCode = error?.graphQLErrors?.[0]?.extensions?.status || 0;
	const field = error?.graphQLErrors?.[0]?.extensions?.details[0]?.field || null;
	const serverMessage = error?.graphQLErrors?.[0]?.extensions?.details[0]?.message || null;

	// Log non-network errors to RUM.
	switch (data) {
		case 'no-url-vars':
			console.error('Form loaded with no URL params');
			break;
		case 'missing-url-vars':
			console.error(
				'Form loaded with missing URL params. Query string: ',
				window.location.search,
			);
			break;
		case 'NO_PRODUCTS':
			console.error('Invalid product sku');
			break;
		default:
			break;
	}

	const paypalError =
		endpoint === 'REQUEST_DIRECT_PAYPAL_ORDER' || endpoint === 'UPDATE_DIRECT_PAYPAL_ORDER';
	const applePayError = endpoint === 'Apple';
	const mutationError = endpoint === 'SUBMIT_ORDER' || paypalError || applePayError;

	const getMutationError = () => {
		if (statusCode === 403 && field === 'kount') {
			// Payment declined
			return { message: 'kount-declined-no-paypal', messageType: 'cart' };
		}
		if (statusCode === 503) {
			return { message: 'connection-fail', messageType: 'cart' };
		}
		if (serverMessage === 'Product is not approved') {
			return { message: 'auth-testOnly', messageType: 'cart' };
		}
		if (paypalError) {
			//  If a paypal submission does not return an id, we can show that message.
			if (data === 'NO_ID' || statusCode === 401) {
				return { message: 'paypal-10417', messageType: 'cart' };
			}
			if (statusCode === 409) {
				return { message: 'paypal-completed', messageType: 'modal' };
			}
		}
		if (statusCode === 409) {
			const receiptNumber =
				error?.graphQLErrors?.[0]?.extensions?.details?.[0]?.rejectedValue;
			if (!receiptNumber) {
				return { message: 'connection-fail', messageType: 'cart' };
			}
			return { message: 'duplicate-order', messageType: 'cart', vars: { receiptNumber } };
		}
		if (applePayError) {
			if (data.error.graphQLErrors) {
				const firstErrorOfArray = data.error.graphQLErrors[0];
				const errorMessage = firstErrorOfArray.message;
				const isAddressError = firstErrorOfArray.extensions.details[0].field === 'address';

				if (
					isAddressError ||
					errorMessage === 'Country not valid' ||
					errorMessage === 'Address not valid' ||
					errorMessage === 'Invalid country'
				) {
					return {
						message: 'submit-apple-pay-invalid-address',
						messageType: 'cart',
					};
				}
			}
			return { message: 'submit-apple-pay-fail', messageType: 'cart' };
		}
		// likely, an issue with form data. user can try again
		return { message: 'auth-fail', messageType: 'cart' };
	};

	const getQueryError = () => {
		if (statusCode === 403) {
			if (field === 'kount') {
				return { message: 'auth-fail', messageType: 'cart' };
			}
			// affiliate blocked or not active
			// we don't want to throw a modal or an error in the cart,
			// so we return the original error and endpoint back to GraphQLLoader
			if (field === 'affiliate') {
				return { error: error, endpoint: endpoint };
			}
		}

		// These happen if there is invalid data in the form
		// input validation should keep this from triggering but need the handler just in case.
		if (statusCode === 400 && field !== 'vendorId') {
			return { message: '400', messageType: 'cart' };
		}

		if (endpoint === 'UPSELL_FLOW_RECEIPTS') {
			return { message: 'upsell', messageType: 'modal' };
		}

		if (endpoint === 'TEMPLATE') {
			return { message: 'template-invalid', messageType: 'modal' };
		}

		return {
			message: 'generic-error',
			messageType: 'modal',
		};
	};

	return mutationError ? getMutationError() : getQueryError();
};

export const checkProductComboErrors = (error) => {
	const rejectedValue =
		error?.graphQLErrors?.[0]?.extensions?.details?.[0]?.rejectedValue || null;
	const rejectedProducts = rejectedValue
		? rejectedValue.split(',').reduce((a, b) => {
				a[b] = true;
				return a;
			}, {})
		: null;
	return rejectedProducts;
};

export const logErrors = (data) => {
	if (typeof data !== 'object') {
		console.error('logErrors called with invalid argument.');
		return;
	}
	const { operation, result } = data;
	const error = result?.error;
	const stringifiedOperation =
		typeof operation === 'string' ? operation : JSON.stringify(operation || {});
	const operationName = stringifiedOperation
		.match(/((query )|(mutation ))\w+/)?.[0]
		.split(' ')[1];
	const statusCode = error?.graphQLErrors?.[0]?.extensions?.status || 0;
	const field = error?.graphQLErrors?.[0]?.extensions?.details[0]?.field || null;
	const serverMessage = error?.graphQLErrors?.[0]?.extensions?.details[0]?.message || null;
	const errorInfo = {
		error,
		statusCode,
		field,
		serverMessage,
	};
	if (!operationName) {
		errorInfo.operation = operation;
	}
	const isPaymentSubmitError = [
		'submitOrderMutation',
		'requestDirectPayPalOrderMutation',
		'applePayCreateSessionMutation',
		'submitDigitalPaymentMutation',
	].includes(operationName);
	if (field === 'kount') {
		errorInfo.error = 'Kount error';
		console.error(
			`Error on ${operationName || 'unparseable operation'}:`,
			JSON.stringify(errorInfo),
		);
		return;
	} else {
		// When a network error occurs (e.g. page unloads, user loses connection, etc.) we end up with
		// an empty object or, in some cases, an object with a `fetchError` that is an empty object.
		const isNetworkError =
			!error ||
			(typeof error === 'object' &&
				(Object.keys(error).length === 0 ||
					(error?.fetchError && Object.keys(error.fetchError).length === 0)));
		if (isNetworkError) {
			console.error(`Network error on ${operationName || 'unparsable operation'}.`);
		} else {
			if (isPaymentSubmitError && statusCode === 401) {
				return;
			}
			console.error(
				`Error on ${operationName || 'unparseable operation'}:`,
				JSON.stringify(errorInfo, (key, value) => {
					if (operationName === 'affiliateQuery' && key === 'payload') {
						// Remove unique payload value to reduce noise in RUM
						return undefined;
					}
					return value;
				}),
			);
		}
	}
};
